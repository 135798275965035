$spacers : (
  0,1,2,3,4,
  5,6,7,8,
  9,10,11,12,
  13,14,15,16,
  17,18,19,20,
  21,22,23,24,
  25,26,27,28,
  29,30,31,32,
  33,34,35,36,
  37,38,39,40,
  41,42,43,44,
  45,46,47,48,
  49,50,51,52,
  53,54,55,56
);
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spacers {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space / 4}rem !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}rem !important;
    }
  }

  .m-#{$space} {
    margin: #{$space / 4}rem !important;
  }

  .p-#{$space} {
    padding: #{$space / 4}rem !important;
  }

  .g-#{$space} {
    gap: #{$space / 4}rem !important;
  }

  .m-x-#{$space} {
    margin-left: #{$space / 4}rem !important;
    margin-right: #{$space / 4}rem !important;
  };

  .p-x-#{$space} {
    padding-left: #{$space / 4}rem !important;
    padding-right: #{$space / 4}rem !important;
  }

  .m-y-#{$space} {
    margin-top: #{$space / 4}rem !important;
    margin-bottom: #{$space / 4}rem !important;
  };

  .p-y-#{$space} {
    padding-top: #{$space / 4}rem !important;
    padding-bottom: #{$space / 4}rem !important;
  }
}

@each $side in $sides {
  .m-#{str-slice($side, 0, 1)}-a {
    margin-#{$side}: auto !important;
  }

  .p-#{str-slice($side, 0, 1)}-a {
    padding-#{$side}: auto !important;
  }
}

.m-x-a {
  margin-left: auto !important;
  margin-right: auto !important;
}

.p-x-a {
  padding-left: auto !important;
  padding-right: auto !important;
}

.m-y-a {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.p-y-a {
  padding-top: auto !important;
  padding-bottom: auto !important;
}
