/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import 'autocomplete';
@import 'scss/new-design/colors';
@import 'scss/new-design/spacing';
html,
body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  // Go easy on the eyes and use something other than `#000` for text
  color: $body-color;
  // By default, `<body>` has no `background-color` so we set one as a best practice.
  background-color: $concrete-gray;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Removes any animations on tlv (transition and animation only if we have to)
.grid-container {
  * {
    transition: none !important;
    animation: none !important;
  }
}

.is-title-clipped {
  // if @mixin() font-size should be passed as arg
  min-height: 5rem;
  max-height: 5rem; // Assume from $body-line-height: 1.5 !default; defined in _generic.scss file
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  // Ensure better compatibility
  overflow-wrap: break-word;
  word-wrap: break-word;
  // Future proof
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

@mixin fai-background {
  background: $white url('/assets/fai/header-bg.jpg') no-repeat top;
  background-size: 130%;
}

input[readonly],
select.has-actions-disabled {
  background-color: #f9f9f9;
  border-color: #f9f9f9;
  color: #b3b3b3;
}

// Trilogy 2.8.0 Polyfill

.select select:not([multiple]) {
  padding-right: 2.5em;
}

.is-horizontal {
  flex-direction: row !important;
}

[hidden] {
  display: none !important;
}

input:disabled {
  border: 2px solid #d1d1d1 !important;
  background-color: #d1d1d1 !important;
  color: #707070 !important;
}

.divider {
  background-color: lightgray !important;
  margin-top: 0 !important;
}

// loading spinner
.action-feedback:before {
  animation: spin 1.5s infinite;
  border-radius: 50%;
  color: #fff;
  content: '';
  font-size: inherit;
  height: 1em;
  left: 0;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 30%;
  transform: translateZ(0) translateY(-50%);
  width: 1em;
}

@keyframes spin {
  0% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
    transform: rotate(0deg);
  }

  5%,
  95% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em,
      -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em,
      -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em,
      -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
    transform: rotate(360deg);
  }
}
