@import 'variables';

.autocomplete {

    background: $white;
    border: 1px solid $grey-light;
    border-radius: 0.25rem;
    padding: 0.2rem 0;
    will-change: transform;
    z-index: 101;

    .empty,
    .autocomplete-item {
        padding: 0.4rem 2.4rem;
    }

    .autocomplete-item {

        text-overflow: ellipsis;
        overflow: hidden;

        &:hover {
            cursor: pointer;
        }

        &.selected,
        &:hover {
            background-color: #007bff; // Change to $blue once we move to trilogy
            color: $white;
            text-decoration: none;
        }
    }

    .autocomplete-highlight {
        font-weight: bold;
    }
}
