@import '@bytel/trilogy-framework/framework/src/utilities/initial-variables';
@import '@bytel/trilogy-framework/framework/src/utilities/derived-variables';
@import '@bytel/trilogy-framework/framework/src/utilities/functions';
@import '@bytel/trilogy-framework/framework/src/utilities/mixins';
// @import '@bytel/trilogy-framework/framework/src/grid/all';

// Colors
$white: #fff !default;
$black: #000 !default;
$body-color : #454545 !default;
$mine-shaft:            lighten(#000, 20%) !default;   // #333 old : $gray-dark
$silver-chalice:        #454545 !default; // #555
$silver-nobel:          #b5b5b5 !default; // #999
$silver-mercury:        #ececec !default; // #eee
$christine:             #ea5b0c !default;
$candy-apple-red:       #f80707 !default; // red
$alabaster:             #f7f7f7 !default;

$brand-primary:         #c8007b !default;
$brand-secondary:       #0055a4 !default;
$brand-success:         #05d0a6 !default;
$brand-info:            #009dcc !default;

$brand-warning:         #ff9966 !default;
$brand-danger:          #fb004c !default;
$brand-customer:        $brand-success !default;
$brand-byou-b:          #00b5de !default;
$brand-byou-y:          #007dc5 !default;
$brand-byou-o:          #39bdab !default;
$brand-byou-u:          #00b25a !default;
$brand-pro:             #583273 !default;
$text-color:            #666666 !default;

$lighter-gray: #e1e1e1 !default;
$fade-grey: #d5d5d5 !default;
$filter-light-grey: #bfbfbf !default;
$alt-light-gray: #cdcdcd !default;
$flat-gray: #e9e9e9 !default;
$gallery-gray: #ececec !default;
$concrete-gray: #f3f3f3 !default;

$dark-violet: #583273 !default;
$royal-violet: #9e358c !default;

$french-rose: #f64b7f !default;
$trendy-pink: #927aa4 !default;

$lighter-green: #9fcf01 !default;
$rotten-green: #9dc90d !default;
$haze-green: #009a75 !default;
$regular-green: #06c303 !default;
$emerald-green: #66cc99 !default;

$bright-yellow: #f2d500 !default;

$lochmara-blue: #0087c0 !default;

$gray-dark:                 #292b2c !default;
$gray:                      #464a4c !default;
$gray-light:                #636c72 !default;
$gray-lighter:              #eceeef !default;
$gray-lightest:             #f7f7f9 !default;

$font-family-sans-serif: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:   1.25rem !default;
$font-size-sm:   .875rem !default;
$font-size-xs:   .75rem !default;

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$font-size-h1: 2.5rem !default;
$font-size-h2: 2rem !default;
$font-size-h3: 1.75rem !default;
$font-size-h4: 1.5rem !default;
$font-size-h5: 1.25rem !default;
$font-size-h6: 1rem !default;

$headings-font-family:   inherit !default;
$headings-font-weight:   500 !default;
$headings-line-height:   1.1 !default;
$headings-color:         inherit !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight:     300 !default;
$display2-weight:     300 !default;
$display3-weight:     300 !default;
$display4-weight:     300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size:   1.25rem !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-light !default;

$abbr-border-color: $gray-light !default;

$blockquote-small-color:  $gray-light !default;
$blockquote-font-size:    ($font-size-base * 1.25) !default;
$blockquote-border-color: $gray-lighter !default;
$blockquote-border-width: .25rem !default;

$hr-border-color: rgba($black,.1) !default;

$mark-padding: .2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow:         inset 0 -.1rem 0 rgba($black,.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 5px !default;

$border-radius:          .25rem !default;
$input-border-radius:            $border-radius !default;
